
import { FaCheck, FaCheckCircle, FaExclamationTriangle, FaFilePdf, FaTrash, FaVideo } from 'react-icons/fa';
import Loader from './loader';
import { TbArrowsMove, TbSettings2 } from 'react-icons/tb';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import apiLink from '../urls';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ImageCard = ({file,index,getFiles}) => {

const cancelButtonRef = useRef(null);
const moveFileRef = useRef(null);
const [allowContinueDelet ,setAllowContinueDelet] = useState(false)
const [open ,setOpen] = useState(false);
let [objToDelete,setObjToDelete] = useState({});
const [loadingDelet,setLoadingDelet] = useState(null);
let canOpenMoveFile =  0;
const [loadingMoveFile,setLoadingMoveFile] = useState(false)
const { id, categorieId } = useParams();
// alert(categorieId)
const [openMoveFile,setOpenMoveFile] = useState(false)


const [selectedFolder,setSelectedFolder]= useState(null);


const [anchorEl, setAnchorEl] = React.useState(null);
const openMenu = Boolean(anchorEl);
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};


async function moveFile(){
    if(selectedFolder){
      
        setLoadingMoveFile(true)
        try {
            const res = await axios.post(`${apiLink}/test.php`,{  action:"MOVE_FILE",fileId:file.id
                ,folderId:selectedFolder})
                if(res.data.success){
                    setOpenMoveFile(false)
                    getFiles()
                    setLoadingMoveFile(false)
                    }
                    } catch (error) {
                        console.log(error)
                        setLoadingMoveFile(false)
                        }
                        }else{
                            alert("please select a folder")
                            }

}

useEffect(() => {
    function handleClickOutside(event) {
      if (moveFileRef.current && !moveFileRef.current.contains(event.target)) {
        setOpenMoveFile(false);
      }
    }

    if (openMoveFile) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMoveFile]);


  const folders =[{name:'Clinic notes',id:1},{name:'Endoscopy',id:2},{name:'Laboratory',id:3},{name:'Radiology',id:4},]
useEffect(()=>{
    if(allowContinueDelet){
      deleteFile()
      setOpen(false)
    }
    
  },[allowContinueDelet,objToDelete])



  useEffect(()=>{
    if(allowContinueDelet){
      deleteFile()
      setOpen(false)
    }
    
  },[allowContinueDelet,objToDelete])




 async function deleteFile(){
  // if(objToDelete.id){
    if(allowContinueDelet){
   

    setLoadingDelet(objToDelete.id);

const obj={
  action:"DELETE_PHOTO",
  id:objToDelete.id,
  name:objToDelete.name
}
  try {
    await axios.post(apiLink + "test.php", obj
    ).then((res) => {
       setObjToDelete({})
      getFiles()
    })
  } catch (e) {
    


  }
    }else{
      setOpen(true)
    }
    setLoadingDelet(null)
setAllowContinueDelet(false)
  // }
 }
  return (
    <>
    <div 
        className=' cursor-pointer w-full relative px-2 py-3 bg-white shadow-md flex flex-row justify-between '>
       {/* {openimage&&<div onClick={()=>setopenimage(false)} className=' z-30 fixed w-full h-full left-0 right-0 bottom-0 top-0 bg-[#00000057]'></div>} */}
        <div className=' flex  flex-row justify-start gap-3'>
        <a 
        href={`${apiLink}images/${file.name}`}
      //   onClick={() => {
      //   setActiveSlide(index)
      //   setopenimage(true)
      // }} 
       className={`relative overflow-hidden w-16 aspect-square  flex justify-center text-center  shadow-sm   `}>
      {file.name.split('.').pop() == 'pdf' && <div className='   my-auto'> <FaFilePdf className=' text-2xl h-fit my-auto'/></div>}
      {(file.name.split('.').pop() == 'png'|| file.name.split('.').pop() == 'jpg'|| file.name.split('.').pop() == 'jpeg') && 
                  <img key={file.id}  className=' bg-[#46b59b5d]  w-full object-cover' src={`${apiLink}images/${file.name}`} />}
                  {file.name.split('.').pop() == 'mp4'&&<div className='   my-auto'> <FaVideo className=' text-2xl h-fit my-auto'/></div>}
        </a>
        <div className=' flex flex-col h-fit gap-1 my-auto'>
          <h2 className=' text-lg text-ellipsis  line-clamp-1  max-w-[200px]'>{file.name}</h2>
          <h2 className=' text-sm text-gray-300 '>{file.date}</h2>
        </div>
        </div>
        <button className=' mr-3'  onClick={handleClick}>
            <TbSettings2/>
        </button>
        {/* <button onClick={()=> {
          setObjToDelete({name : file.name, id : file.id});  
          setOpen(true)
        }
       
      } className=' w-fit p-3 bg-red-500 rounded-full text-white h-fit my-auto '>{ loadingDelet == file.id  ? <Loader/> :<FaTrash/>}</button> */}
     
     <div className=' absolute top-16  h-fit '>
       <div ref={moveFileRef} className={` ${openMoveFile?"  p-2 scale-100 opacity-100 h-fit  translate-y-0":" scale-90 h-0 opacity-0  -translate-y-2"} z-30  duration-200  flex flex-col justify-start text-left rounded-md relative overflow-hidden  w-72 bg-white  shadow-md`}>
          <h2 className=' border-b border-[#46B59B] border-opacity-35 flex justify-start gap-3 text-xl font-extrabold '> <TbArrowsMove className=' text-[#46B59B] my-auto'/> <span>Move to</span></h2>
          
          <ul className=' flex  mt-2 flex-col gap-2'>
            {folders.map((folder, index) => (

                folder.id == categorieId ?<></> : <button className={` bg-opacity-30 px-3 py-1 flex justify-between ${folder.id == selectedFolder?"bg-[#46B59B]":"bg-white"}`} onClick={()=>setSelectedFolder(folder.id)} ><span>{folder.name}</span><span className=' my-auto'>{folder.id == selectedFolder?<FaCheckCircle className=' my-auto text-[#46B59B]'/>:""}</span></button>
            ))}
          </ul>
      

      <div className=' flex flex-row mt-2 justify-end gap-2'>
        <button onClick={()=>setOpenMoveFile(false)} className=' bg-gray-200 rounded-full
        p-2 text-sm'>Cancel</button>
        <button 
        onClick={()=>moveFile()}
        className=' bg-[#46B59B] rounded-full p-2 text-sm text-white
        '> {loadingMoveFile? <Loader/> : "Move"}</button>
      </div>
      </div>
      </div>













      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
   
        <MenuItem onClick={()=>{ setObjToDelete({name : file.name, id : file.id});  
          setOpen(true)}}>
          <ListItemIcon>
            <FaTrash />
          </ListItemIcon>
          Delete
        </MenuItem>
        <MenuItem  onClick={()=>{
            setOpenMoveFile(true)
        }} >
          <ListItemIcon >
            <TbArrowsMove />
          </ListItemIcon>
          Move
        </MenuItem>
      </Menu>
      </div>








      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Deleting File
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to this file? You will not be able to get it back.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      setAllowContinueDelet(true)
                      // deleteFile()
                      setOpen(false)
                    }
                  }
                  >
                    confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>






</>
  )
}

export default ImageCard