

import axios from 'axios';
import Header from '../components/header';
import PatientCard from '../components/patientCard';
import { useContext, useEffect, useState } from 'react';
import apiLink from '../urls';
import Loader from '../components/loader';
import { PatientContext } from '../contexts/patientsContext';

const Home = () => {
  const [state,dispatch] = useContext(PatientContext);

 const [loading,setLoading] = useState(false);

 
     useEffect(()=>{
      if(state.reloadPatients){
      getPatients();
      }
     },[state.reloadPatients])
 
  async function getPatients(){
    setLoading(true)
    await axios.get(apiLink+"webapi.php").then((res)=>{
      setLoading(false)
      dispatch({type:"setListPatients",payload: res.data.patients})
      dispatch({type:"setReloadPatients",payload: false})
 
    });
  
  }
  useEffect(()=>{
    
    getPatients();
  
  },[])






  return (
    <>
      <Header/>
   <div className='flex flex-col h-full  divide-y-2 w-full container mx-auto'>
 {loading&&
 <div className='fixed  flex justify-center text-center z-40 w-screen h-screen top-0 left-0 bottom-0 right-0'>
 <div className='  h-fit my-auto'>
<Loader/>
    </div>
</div>
}

{ state.listPatients&& state.listPatients.map((patient)=>{
  return<><PatientCard ispinned={patient.ispinned} date={patient.date_add} name={patient.name} id={patient.id}   phone={patient.phone}/></>
})}


  

   </div>
   </>
  )
}

export default Home