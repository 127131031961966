import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaAngleLeft, FaCamera, FaEdit, FaFilePdf, FaTrash, FaVideo, FaWindowClose } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import imageCompression from 'browser-image-compression';
import apiLink from '../urls';
import PrismaZoom from "react-prismazoom";
import { format } from 'date-fns';
import Loader from '../components/loader';
import { TbArrowsMove, TbCameraPlus, TbMoodEmpty } from "react-icons/tb";
import useDeviceSize from '../services/usedevicewidth';

import { Dialog, Transition } from '@headlessui/react'
import {FaExclamationTriangle,FaSignOutAlt } from "react-icons/fa";


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ImageCard from '../components/imageCard';

const CategoriePage = () => {
  const [open ,setOpen] = useState(false);
  const [openNameFile ,setOpenNameFile] = useState(false);
  const [allowContinueDelet ,setAllowContinueDelet] = useState(false)
const [enableSwiper ,setEnableSwiper]= useState(true)
  const [emptyFile, setEmptyFile] = useState(false);
  const [globalFileName,setFileName] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false)
  const location = useLocation();
  const history = useNavigate();
  const { name, cate } = location.state;
  const [listFiles, setListFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id, categorieId } = useParams();
  const imageRef = useRef(null);
  const [openimage,setopenimage]=useState(false);
  const [activeSlide ,setActiveSlide]= useState(0);
const cancelButtonRef = useRef(null);


  async function handleChangeImage(e) {
e.preventDefault();
    const files = imageRef.current.files; // Access all selected files
  //  console.log(files)
  for (let i = 0; i < files.length; i++) {
  
    const file = files[i];
    // console.log(file)
    await compressImage(file , i);
  }

  }






  const uploadImage = async (file,indexFile) => {

  //  setOpenNameFile(true);
  
  console.log("______________________________")
    console.log(imageRef.current.files);
    setLoadingUpload(true);
    const currentDate = new Date();

    // Format the date as 'dd/MM/yy'
    const formattedDate = format(currentDate, 'dd-MM-yy HH:mm:ss');
    const formData = new FormData();
    formData.append('image', file);
    formData.append('action', 'ADD_PHOTO');
    formData.append('patient', id);
    formData.append('date', formattedDate);
    formData.append('category_id', categorieId);
    // formData.append('name',fileName)
    // const obj = {
    //   action: "ADD_PHOTO",

    // };

  



    try {
      await axios.post(apiLink + "test.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      ).then(async (res) =>  {
        if(indexFile === imageRef.current.files.length - 1){
          setFileName('')
        setLoadingUpload(false)
          imageRef.current.value = '';
        getFiles()
        }
      })
    } catch (e) {
      setLoadingUpload(false)


    }

  }

  


  const getFiles = async () => {
    setLoading(true)
    const obj = {
      action: "GET_PHOTOS",
      idPatient: id,
      idCate: categorieId
    };
    try {
      await axios.post(apiLink + "test.php", obj
      ).then((res) => {

        if (res.data.message === "No data found") {
          setEmptyFile(true);
        } else {

          setListFiles(res.data.data);
          setEmptyFile(false);
        
        }
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
      console.log(e)

    }
  }


  async function compressImage(file , indexFile) {
  

    try {
      const options = {
        maxSizeMB: 10,          // Maximum size of the compressed image (in megabytes)
        maxWidthOrHeight: 2000,   // Maximum width or height of the compressed image
        useWebWorker: true,      // Use web workers for faster compression (recommended)
      };
      
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'dd-MM-yy HH:mm:ss');
      
      // Assuming 'file' is obtained from a file input
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      const mimeType = file.type;
      if(fileExtension =="jpg"||fileExtension =="png"||fileExtension =="jpeg"){
      const compressedImage = await imageCompression(file, options);
      const compressedImageFile = new File(
        [compressedImage],
        `${globalFileName}_${formattedDate}.${fileExtension}`,
        { type: mimeType }
      );

      await uploadImage(compressedImageFile , indexFile);
      }else{
        const compressedImage = file;
        const compressedImageFile = new File(
          [compressedImage],
          `${globalFileName}_${formattedDate}.${fileExtension}`,
          { type: mimeType }

        );

      await uploadImage(compressedImageFile , indexFile);
      }
   



      // You can now upload the compressed image to your server or do other things with it
    } catch (error) {
      console.error('Error compressing image:', error);

    }
  }



  useEffect(() => {
    getFiles();
  }, [])

  return (
    <>
<div className=' relative '>
      <div className='sticky  top-0 z-50 text-white bg-[#46B59B] w-full shadow-lg'>
        <div className='container flex justify-between text-center py-5 mx-auto max-md:px-5  '>
          <div className=' flex flex-row justify-center gap-5 text-center'>
            <button onClick={() => {
              history(-1)
            }} className=' text-lg h-full my-auto'><FaAngleLeft /></button>
            <h2 className='text-xl   max-md:w-60 w-full  truncate font-extrabold font-serif'>{`${cate}(${name})`} </h2>
          </div>

         
        </div>


      </div>


      <div className=' mt-5 container mx-auto relative flex flex-col gap-2'>
        <label className=' w-full py-4   text-xl rounded-md flex justify-center text-center border-blue-950 bg-[#46B59B] bg-opacity-15 text-[#46B59B] border border-dashed'>
      
              <input ref={imageRef} onChange={() => { 
                // handleChangeImage()
                setOpenNameFile(true)
                }} type='file' name='files' multiple className=' hidden' />
              { loadingUpload ? <Loader /> :  <TbCameraPlus />}
       
          
        </label>
        {listFiles && listFiles.map((file,index) => {
          return  <ImageCard  getFiles={()=>getFiles()} file={file} index={index}/>
        })}

    

      </div>



      {emptyFile && <div className=' h-screen '><div className='h-full flex flex-col justify-center my-auto bg-blue-50 text-center gap-5 '><div className=' w-fit text-center mx-auto'><TbMoodEmpty className=' text-xl text-[#46B59B]' /> </div>
        <h2 className=' text-md'>This folder is empty</h2>
        <span className=' text-sm text-gray-500'>click at the camera on top to add files</span>
      </div></div>}




          </div>















<Transition.Root show={openNameFile} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenNameFile}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0   z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full  items-end justify-center p-4  sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
               <form onSubmit={(e)=>{
                  handleChangeImage(e)
                setOpenNameFile(false)
              
                }} >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex  sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  bg-[#46b59b5d] sm:mx-0 sm:h-10 sm:w-10">
                      <FaEdit className="h-6 w-6 text-[#46B59B]" aria-hidden="true" />
                    </div>
                    <div className="mt-3   w-full relative overflow-hidden  sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                       Change File Name
                      </Dialog.Title>
                      <div className=' px-9 border-dashed h-1 border-t-2 '></div>
                      <div className="mt-4">
                      <div className="">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-500">
                Name
              </label>
              <div className="mt-2">
                <input
                onChange={(e)=>setFileName(e.target.value)}
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block  w-full transition-all rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:bg-blue-200 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
                      </div>

                   
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md  bg-[#46B59B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#46B59B] sm:ml-3 sm:w-auto"
                    
                  >
                    {loading?<Loader/>:"Upload"}

                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpenNameFile(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>


                </form>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}

export default CategoriePage